//COLORS
$colorDarkIndigo: #071326;
$colorBlack: #141316;
$colorWhite: #FFFFFF;
$colorLime: #eefa13;
$colorGray: #27252B;
$colorGray-2: #B0ACB7;
$colorGray-3: #44404B;
$colorGray-4: #1f2b3d;
$colorGrayLight: rgba(#FFFFFF, 0.5);
$colorBlue: #5337fd;
$colorDarkBlue: #3A23AE;
$colorOutlineGray: #2C3746;
$colorOutlineBlue: #5F45FF;
$colorStatusDisabled: #3A3740;
$colorStatusOnline: #3AB795;
$colorStatusError: #E7B008;
$colorStatusOffline: #FE4A49;


//SIZES
$borderRadius: 15px;
$topbarH: 70px;


//MEDIA QUERIES
$fullHdWidth: 1920px;
$hdReadyWidth: 1024px;
$tabletSmallWidth: 767px;
$tabletMediumWidth: 820px;
$tabletLargeWidth: 912px;

$phoneLargeWidth: 470px;
