//
//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat.core();
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/
//$three-ton-app-poc-primary: mat.define-palette(mat.$indigo-palette);
//$three-ton-app-poc-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
//// The warn palette is optional (defaults to red).
//$three-ton-app-poc-warn: mat.define-palette(mat.$red-palette);
//
//// Create the theme object. A theme consists of configurations for individual
//// theming systems such as "color" or "typography".
//$three-ton-app-poc-theme: mat.define-light-theme((
//  color: (
//    primary: $three-ton-app-poc-primary,
//    accent: $three-ton-app-poc-accent,
//    warn: $three-ton-app-poc-warn,
//  )
//));
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include mat.all-component-themes($three-ton-app-poc-theme);


@import "abstracts/variables";
@import "abstracts/utilities";
@import "base/typography";
@import "theme/3ton-material";
@import "base/base";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
