@import "abstracts/variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

//@font-face {
//  font-family: 'Inter', sans-serif;
//}


body, html  {
  font-smooth: never;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  -moz-font-feature-settings: "liga=1, kern=1";
  -ms-font-feature-settings: "liga", "kern";
  -o-font-feature-settings: "liga", "kern";
  -webkit-font-feature-settings: "liga", "kern";
  font-feature-settings: "liga", "kern";
}

body, p, .mat-checkbox, .mat-step-icon-content, .mat-radio-label-content {
  font-family: 'Inter', sans-serif !important;
  font-size: 18px;
  line-height: 30px;
  color: $colorWhite;
  font-weight: 400;

}

h1, h2, h3, h4, button, a {
  font-family: "Inter",sans-serif !important;
}

h1 {
  font-size: 24px !important;
  line-height: 34px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 24px !important;
  line-height: 34px !important;
  font-weight: 500 !important;
}

h3 {
  font-size: 40px !important;
  line-height: 40px !important;
  font-weight: 700 !important;

}

h4, .mat-step-label  {
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600 !important;

}

button, a  {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 600 !important;

}

.mat-error {
  font-size: 14px;
}


