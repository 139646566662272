@import "../abstracts/variables";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  box-sizing: border-box;
}
html, body { height: 100%; }

body {
  margin: 0;
  background-color: $colorBlack;
  color: $colorWhite;
}

