

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "3ton-palette-accent";
@import "3ton-palette-primary";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$three-ton-primary: mat.define-palette($md-3ton-primary, 500);
$three-ton-accent: mat.define-palette($md-3ton-accent, 500);

// The warn palette is optional (defaults to red).
$three-ton-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$three-ton-theme: mat.define-dark-theme((
  color: (
    primary: $three-ton-primary,
    accent: $three-ton-accent,
    warn: $three-ton-warn,
  ),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($three-ton-theme);

/* You can add global styles to this file, and also import other style files */


.mat-form-field, .mat-mdc-text-field-wrapper, .mdc-text-field--outlined{
  font-size: 16px !important; // example
  padding: 0 !important;
  line-height: 16px !important;
  height:44px !important;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 12px 16px;
}

.mat-row {
  min-width: max-content;
}


//.mat-form-field-appearance-outline  {
//  & .mat-form-field-outline-start {
//    border-radius: 28px 0 0 28px !important;
//    min-width: 28px !important;
//  }
//
//  & .mat-form-field-outline-end {
//    border-radius: 0 28px 28px 0 !important;
//  }
//}

//.mat-mdc-raised-button {
//
//  border-radius:10px !important;
//}
//
//.mat-mdc-raised-button[disabled] {
//  border-radius: 10px !important;
//}
//
//.mat-mdc-form-field {
//  line-height: 2 !important;
//}
//
//.mat-mdc-text-field-wrapper {
//  height: 44px !important;
//}
//
//.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
//  top: 12px;
//  left: -10px;
//}
//.mat-mdc-tooltip {
//  font-size: 10px !important;
//}
//
//td.mat-mdc-cell, tr.mdc-data-table__cell, .mat-mdc-cell,
//tr.mdc-data-table__cell, tr.mat-mdc-table, tr.mdc-data-table__row {
//  font-size: 10px !important;
//  height: 30px !important;
//  padding: 4px 16px !important
//}


//.mat-raised-button, .mat-stroked-button {
//  border-radius: 28px !important;
//  padding: 4px 24px !important;
//
//  &.mat-button-disabled {
//    background-color: red !important;
//  }
//}

//.mat-stroked-button {
//  border: 2px solid $colorLime !important;
//
//  &.mat-button-disabled {
//    border: 2px solid $colorGrayLight !important;
//    color: $colorGrayLight !important;
//    background-color: transparent !important;
//  }
//}
//
//.mat-stepper-vertical {
//  background-color: transparent;
//}
//
//
//
//.mat-checkbox-label, .mat-radio-label-content  {
//  white-space: normal !important;
//}
//.mat-checkbox-frame {
//  //border-color: $colorGrayLight;
//}
//
//.mat-step-header,   {
//  //padding-left: 0 !important;
//}
//
//.mat-vertical-content-container {
//  //margin-left: 10px !important;
//}
//button {
//
//  //width: 100px;
//}
//
//.mat-card {
//  background: $colorGray;
//  //border-radius: 25px !important;
//}
//
//.th-center {
//  & > .mat-sort-header-container {
//    justify-content: center !important;
//
//    & > .mat-sort-header-content {
//      margin-left: 20px !important;
//    }
//  }
//}
//
//.input-no-bottom-padding {
//  & > .mat-form-field-wrapper {
//    padding-bottom: 0 !important;
//  }
//}
//
//
//
