@import "abstracts/variables";

.u-new-line {
  display: block;
}

.fx {
  display: flex;
}

.fx-v-center {
  align-items: center;
}

.fx-h-center {
  justify-content: center;
}
.fx-h-space-between {
  justify-content: space-between;
}
